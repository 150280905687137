<template>
  <div class="text-center m-auto">
    <img alt="No Activity Found" class="error-img" src="@/assets/images/no-activity-found-new.svg">
    <div v-if="selectedCompanyUserStatus !== 'employee'">
        <p class="error_text" v-if="!getData && selectedCompanyUserStatus !== 'employee'">Please select the team member and
            press search button</p>
        <p class="error_text" v-else>No Data Found</p>
    </div>
    <div v-else>
      <p class="error_text" v-if="getDataLoader == true && selectedCompanyUserStatus == 'employee'">Loading</p>
      <p class="error_text" v-else>No Data Found</p>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name : "no-activity-found",
    mounted () {
      this.$store.commit ( 'custom/image_data', false )
    },
    computed : {
      ... mapState ( 'custom', [ 'selected_company', 'companies_list' ] ),
      selectedCompanyUserStatus () {
        const company = this.companies_list.find (
          c => c.id === this.$route.params.id
        );
        if ( company ) return company.user_status;
        else {
          this.$store.dispatch ( "custom/redirectToCompaniesListing" );
        }
      },
      getData () {
        return this.$store.state.custom.search_image;
      },
      getDataLoader () {
        return this.$store.state.custom.show_loader;
      }
    }
  }
</script>

<style scoped>
  .error_text {
    font-size   : larger;
    font-weight : 500;
    color       : #A9BCDB;
    margin-top  : 20px;
  }

  .error-img {
    height     : 250px;
    width      : 300px;
    margin-top : 30px;
  }
</style>
